header.ClientProfHeader-container {
	width: 100%;
	box-shadow: none;
}
.ClientProfHeader-container h4 {
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
	position: relative;
}
.ClientProfHeader-container .prof-details {
	padding: 20px 20px 0;
	background: #f5f5f5;
}
.ClientProfHeader-container .prof-detail {
	margin: 0 4px;
	background: #fff;
	padding: 6px 8px;
	border-radius: 4px;
	vertical-align: top;
	display: inline-block;
	font-weight: bold;
	line-height: 1;
	font-size: 13px;
	color: rgba(0, 0, 0, 0.75);
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.ClientProfHeader-container .birthdate {
	font-size: 13px;
	margin-top: 10px;
}
.ClientProfHeader-container .client-photo {
	width: 150px;
	height: 150px;
	border-radius: 150px;
	margin: 0 auto 10px;
	border: 4px solid white;
	position: relative;
}
.ClientProfHeader-container .photo-background {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 90px;
	background: linear-gradient(to bottom, #006bee, #119bff);
}
.ClientProfHeader-container .ImageInput-container {
	border: 0;
	background: none;
	position: relative;
	padding-top: 0;
}
.ClientProfHeader-container .ImageInput-container .image-preview {
	width: 150px;
	height: 150px;
	border-radius: 150px;
	border: 4px solid white;
}
