.overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	text-align: center;
	background: rgba(0, 70, 200, 0.6);
	color: white;
	z-index: 1;
}
