.MealCard-container {
	background: white;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
	margin: 20px 0;
	overflow-y: hidden;
	white-space: nowrap;
	transition: 500ms;
	position: relative;
	border: 0;
	border-radius: 8px;
	/* animation: slide-in 1s ease-in-out 1; */
}
.MealCard-container > header {
	z-index: 1;
	position: relative;
	background: white;
}
.MealCard-container > header .calories-remaining {
	font-size: 12px;
	line-height: 24px;
}
.MealCard-container > main {
	height: calc(100% - 80px);
	background: white;
	overflow-y: auto;
}
.MealCard-container section:nth-child(1) {
	height: 50%;
}
.MealCard-container .meal-card-btns {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	background: white;
}
.MealCard-container .meal-card-btns > button {
	border-radius: 6px;
	line-height: 2.5;
	font-size: 12px;
}

.MealCard-container .meal-card-foods {
	list-style: none;
	padding: 0;
	margin: 0;
}

.MealCard-container .meal-card-foods li + li {
	margin-top: 8px;
	padding-top: 8px;
	border-top: 1px solid #eee;
}

.MealCard-container .meal-card-foods li header strong {
	width: calc(100% - 22px);
	display: inline-block;
	white-space: normal;
}
.MealCard-container .meal-card-foods li main {
	width: calc(100% - 30px);
	display: inline-block;
	white-space: normal;
}
.MealCard-container .meal-card-foods li footer {
	width: 30px;
	display: inline-block;
}
.MealCard-container .meal-card-foods li button {
	font-size: 12px;
	line-height: 20px;
	width: 22px;
	background: none;
}

.strong {
	font-weight: 900;
}

.input-meal-name {
	border: none;
	padding: 0;
}
.input-meal-name:focus {
	outline-color: #007bff;
	outline-width: 4px;
}

@keyframes slide-in {
	0% {
		transform: translate(110vw, 0);
		opacity: 0;
	}
	100% {
		transform: translate(0, 0);
		opacity: 1;
	}
}
