.About-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: lightskyblue;
	color: white;
	animation: changer 4000ms ease-in infinite forwards;
}

@keyframes changer {
	0% {
	}
	50% {
		background: lightgreen;
	}
	100% {
		background: lightskyblue;
	}
}
