.Login-container {
	margin-top: 50px;
	height: calc(100vh - 50px);
	overflow-x: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: rgb(48, 48, 48);
	transition: 250ms;
}

.Login-container > div {
	min-width: 350px;
	width: 100%;
	max-width: 400px;
	background-color: #007bff;
	transition: all 500ms, height 100ms;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 20px;
	border-radius: 20px;
}

.Login-container > div button:focus {
	outline: none;
}

.Login-container > div h2 {
	font-weight: 700;
	color: white;
	margin: 0;
}

.Login-container > div form {
	padding: 20px;
	min-width: 90%;
}

.Login-container > div form .Input-container + .Input-container {
	margin-top: 20px;
}

.Login-container > div form .password-field {
	position: relative;
	margin-top: 20px;
}

.Login-container > div form .password-field + .Input-container {
	margin-top: 20px;
}

.Login-container > div form input[type="submit"] {
	margin: 20px auto 0;
	height: 42px;
	width: 160px;
	display: inherit;
	align-self: center;
	border-radius: 21px;
	background-color: white;
	color: #007bff;
	border: none;
	font-size: 20px;
	font-weight: 700;
	outline: none;
	padding: 0;
	box-shadow: none;
	-webkit-box-shadow: none;
}
.Login-container > div form button {
	margin-top: 10px;
}
.Login-container > div form .show-pswd-btn {
	background-color: white;
	line-height: initial;
	font-size: 12px;
	font-weight: 700;
	position: absolute;
	right: 4px;
	top: 8px;
	padding: 12px;
	z-index: 1;
	color: rgba(0, 0, 0, 0.33);
}
.Login-container > div form .frgt-pswd-btn {
	background-color: rgba(0, 0, 0, 0);
	color: white;
	line-height: initial;
	font-size: 12px;
	font-weight: 700;
}
.Login-container > div form .tgle-signup-login-btn {
	height: 30px;
	background-color: rgba(0, 0, 0, 0);
	color: white;
	line-height: initial;
	font-size: 16px;
	font-weight: 700;
}

.trainer-check {
	width: 100%;
	position: relative;
	border: 3px solid rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	overflow: hidden;
	transition: 0.3s;
	background: white;
	margin-top: 20px;
	line-height: 1.2em;
	background: #fff;
	-webkit-appearance: none;
	-webkit-box-shadow: 1px 1px 1px #fff;
	font-weight: 700;
	color: rgba(0, 0, 0, 0.33);
	display: flex;
	justify-content: space-between;
}

.trainer-check > div {
	width: 49.85%;
	text-align: center;
	line-height: 2;
}
.trainer-check > div:nth-child(1) {
	border-right: 2px solid rgba(0, 0, 0, 0.1);
}
.trainer-check > div:nth-child(2) {
	border-left: 2px solid rgba(0, 0, 0, 0.1);
}
