.EditItem-container {
	padding: 10px;
	overflow-y: auto;
}

.EditItem-container .food-card {
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
	border-radius: 8px;
}

.EditItem-container h4 {
	margin: 0;
	padding: 20px;
	text-align: center;
	font-weight: 600;
}
.EditItem-container .input-section {
	position: relative;
}
.EditItem-container .serving-unit {
	position: absolute;
	top: 36px;
	right: 12px;
	font-size: 28px;
	color: rgba(0, 0, 0, 0.33);
}

.EditItem-container footer {
	position: fixed;
	bottom: -2px;
	left: 0px;
	right: 0px;
	padding: 10px;
	background: white;
	display: flex;
	justify-content: space-between;
	border-top: 1px solid #eee;
	z-index: 1;
}

.EditItem-container footer button + button {
	width: calc(100% - 52px);
}
