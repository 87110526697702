.PeopleCard-container {
	height: 100px;
	width: 90%;
	padding: 5px 10px;
	background-color: lightblue;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;
	box-sizing: border-box;
}

.PeopleCard-container .mock-img {
	height: 75px;
	width: 75px;
	background-color: cornflowerblue;
	border-radius: 50%;
}
.PeopleCard-container > section {
	height: 75px;
}

.PeopleCard-container .btn-box {
	height: 75px;
	width: 100px;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: space-between;
}

.PeopleCard-container .btn-box .ppl-card-btns {
	height: 30px;
	line-height: 0;
}
.PeopleCard-container .btn-box .ppl-card-btns:focus {
	outline: none;
	background-color: #0062cc;
	border: 2px solid #2d93ff;
}
