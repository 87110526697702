.Search-container {
	padding: 12px;
}
.Search-container input {
	width: calc(100% - 46px);
	display: inline-block;
	border: 3px solid rgba(0, 0, 0, 0.1);
	padding: 3px 8px;
	box-shadow: none;
}
.Search-container input:focus {
	border-color: #007bff;
	box-shadow: none;
}
.Search-container button {
	border-radius: 17px;
	padding: 0;
	width: 36px;
	line-height: 34px;
	box-sizing: border-box;
	vertical-align: top;
}
