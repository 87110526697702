button {
	font-size: 14px;
	line-height: 40px;
	border: 0;
	border-radius: 20px;
	cursor: pointer;
}

button.focus {
	outline: none;
	box-shadow: inset 0 0 1px black;
}

button.primary {
	background: #007bff;
	font-weight: 700;
	border: 0;
	color: white;
	transition: background-color 0.3s;
}

button.primary:hover {
	background: #0044cc;
}

button.secondary {
	color: rgba(0, 0, 0, 0.75);
	background: #f5f5f5;
	transition: background-color 0.3s;
}

button.secondary:hover {
	background: #ddd;
}

button.danger {
	color: white;
	background: #c70101;
	font-weight: 700;
}

button[disabled] {
	color: rgba(0, 0, 0, 0.33);
	background: transparent;
	box-shadow: inset 0 0 0px 3px rgba(0, 0, 0, 0.1);
}
