.container,
.macroInputsContainer {
	max-width: 100%;
	width: 100%;
	margin-top: 16px;
}
.container {
	text-align: center;
}
.macroInputsContainer > section:first-child {
	display: flex;
	justify-content: space-between;
}
.macroInputsContainer > section:first-child > div {
	width: calc(100% / 4);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.macroInputsContainer b,
.macroInputsContainer input {
	width: 75%;
}
.macroInputsContainer b {
	text-align: center;
}
.macroInputsContainer b > span {
	height: 10px;
	width: 10px;
	display: inline-block;
}
.macroInputsContainer input {
	background-color: white;
	border: none;
	text-align: center;
	transition: 500ms;
	box-sizing: border-box;
	border-radius: 20px;
	font-weight: 700;
}
