.Landing-container {
	height: calc(100vh - 50px);
	margin-top: 50px;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-sizing: border-box;
}

.Landing-container > header {
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}

.Landing-container > section > a {
	background-color: white;
	color: #007bff;
	height: 15vh;
	width: 75vw;
	border: none;
	margin: 10px;
	border-radius: 10px;
	transition: 500ms;
	text-decoration: none;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.5rem;
	box-shadow: 0 4px 10px #0001;
	border-left: 10px solid #007bff;
}

.Landing-container > section > a:active {
	background-color: #0055dd;
	color: white;
}

/* .Landing-container > a:nth-child(2) {
	/* margin-bottom: 70vh; */
/* } */
.Landing-container > section > a:focus {
	border: 1px solid rgb(121, 255, 255);
}

.Landing-container .menu-section {
	height: calc(100% - 96px);
}
