.ClientCard-container {
	display: flex;
	align-items: center;
	padding: 20px;
	background: white;
	box-sizing: border-box;
	overflow: hidden;
	border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.ClientCard-container > div:nth-child(1) {
	display: flex;
}

.ClientCard-container h6 {
	margin: 0;
}
.ClientCard-container span {
	font-size: 13px;
	color: rgba(0, 0, 0, 0.75);
}

.ClientCard-container .client-photo {
	width: 75px;
	height: 75px;
	border-radius: 50px;
	margin-right: 20px;
}
