.Drawer-container {
	position: absolute;
	left: 0;
	height: 100%;
	width: 100vw;
	background-color: #f5f5f5;
	z-index: 7;
	transition: 500ms;
	border-top: 1px solid #eee;
}
.Drawer-container header {
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}
.Drawer-container h4 {
	margin: 0;
}
.Drawer-container > main {
	height: calc(100% - 120px);
	overflow-y: auto;
}
.Drawer-container > main > .Input-container + .Input-container {
	margin-top: 20px;
}
.Drawer-container > footer {
	display: flex;
	justify-content: space-between;
	background: white;
	border-top: 1px solid #eee;
	padding-top: 10px;
	padding-bottom: 10px;
}

.Drawer-container > footer > button {
	height: 100%;
	transition: 500ms;
}
.Drawer-container > footer > button + button {
	width: calc(100% - 52px);
}
