.ne-Select-container {
	width: 100%;
	border-bottom: 1px solid lightgrey;
	margin-bottom: 20px;
}
.ne-Select-container > label {
	min-width: 120px;
	display: inline-block;
}
.ne-Select-container > select {
	width: calc(100% - 120px);
}
.ne-Select-container > select:disabled {
	color: black;
}
