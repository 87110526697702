.drop-down-input {
	width: 100%;
	position: relative;
	border: 3px solid rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	overflow: hidden;
	background: white;
	transition: 0.3s;
}
.drop-down-input:focus-within {
	border-color: #007bff;
}
.drop-down-input > select {
	background-color: rgba(0, 0, 0, 0);
	padding: 22px 10px 6px;
	border: none;
	width: 100%;
	font-size: 18px;
	position: relative;
	z-index: 1;
	border-radius: 0;
}
.drop-down-input > label {
	position: absolute;
	left: 10px;
	transition: 500ms;
	font-weight: 700;
	font-size: 12px;
	top: 4px;
	color: rgba(0, 0, 0, 0.87);
}
.drop-down-input > input:focus {
	outline: none;
}
