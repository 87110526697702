.Account-container > main {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.Account-container li {
	width: 240px;
	list-style: none;
}

.Account-container li + li {
	margin-top: 20px;
}

.Account-container > main > form {
	width: 50vw;
	overflow: hidden;
	transition: 500ms;
	margin-bottom: 10px;
}

.Account-container > main > .my-account-btn {
	color: #007bff;
	font-size: 20px;
	transition: 500ms;
}
.Account-container > main > .my-account-btn:focus {
	outline: none;
}
.Account-container > main form .password-field {
	position: relative;
	margin-top: 20px;
}

.Account-container > main form .show-pswd-btn {
	background-color: white;
	line-height: initial;
	font-size: 12px;
	font-weight: 700;
	position: absolute;
	right: 4px;
	top: 8px;
	padding: 12px;
	z-index: 1;
	color: rgba(0, 0, 0, 0.33);
}

.Account-container > main input[type="submit"] {
	width: 50vw;
	background: rgba(0, 0, 0, 0);
	outline: none;
	border: none;
	color: #007bff;
}
