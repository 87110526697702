.MultiAddInput-container header {
	display: flex;
	justify-content: space-between;
}
.MultiAddInput-container label {
	font-weight: bold;
	display: inline-block;
	margin: 0;
	font-size: 24px;
	line-height: 40px;
}

.MultiAddInput-container button {
	line-height: 40px;
	border-radius: 20px;
	background: #007bff;
	color: white;
	font-weight: 700;
	padding: 0 32px;
	display: inline-block;
}

.MultiAddInput-container ul {
	padding: 0;
	margin: 20px 0 0;
}

.MultiAddInput-container li {
	list-style: none;
	width: 100%;
	padding: 0;
	margin-bottom: 10px;
	display: inline-block;
}

.MultiAddInput-container li > textarea {
	width: calc(100% - 36px);
	border: 3px solid rgba(0, 0, 0, 0.1);
	margin-right: 6px;
	box-shadow: none;
	vertical-align: middle;
}
.MultiAddInput-container li > textarea:focus:not([disabled]) {
	border-color: #007bff;
	outline: none;
}
.MultiAddInput-container li > textarea[disabled] {
	width: 100%;
	background: #f5f5f5;
}

.MultiAddInput-container li .remove-btn {
	width: 30px;
	line-height: 30px;
	display: inline-block;
	text-align: center;
	font-weight: bold;
	color: rgba(0, 0, 0, 0.5);
	vertical-align: middle;
}
