.Input-container {
	width: 100%;
	position: relative;
	border: 3px solid rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	overflow: hidden;
	transition: 0.3s;
	background: white;
}

.Input-container:focus-within:not(.readonly) {
	border-color: #007bff;
}
.Input-container + .Input-container {
	margin-top: 40px;
}
.Input-container > input {
	background-color: rgba(0, 0, 0, 0);
	padding: 22px 10px 6px;
	border: none;
	width: 100%;
	font-size: 18px;
	position: relative;
	z-index: 1;
	border-radius: 0;
}
.Input-container > label {
	position: absolute;
	top: 12px;
	left: 10px;
	transition: 500ms;
	font-weight: 700;
	color: rgba(0, 0, 0, 0.33);
}
.Input-container > input:focus {
	outline: none;
}
.Input-container.has-data > input + label,
.Input-container > input:focus + label {
	font-size: 12px;
	top: 4px;
	color: rgba(0, 0, 0, 0.87);
}
.Input-container.readonly {
	background: #f5f5f5;
}
.Input-container.readonly > label,
.Input-container.has-data.readonly > label,
.Input-container.readonly > input {
	color: rgba(0, 0, 0, 0.5);
}
