.MealModal-container .manual-entry-fields {
	height: 100%;
	padding-bottom: 20px;
}
.MealModal-container .manual-entry-fields > form {
	height: calc(100% - 40px);
	padding: 20px 20px 0;
	overflow-y: auto;
}

.MealModal-container .manual-entry-fields > form .Input-container {
	margin-bottom: 20px;
}
