.navbar-container {
	position: fixed;
	top: 0;
	z-index: 5;
	height: 50px;
	width: 100vw;
	background-color: #007bff;
	color: white;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 10px;
	box-sizing: border-box;
	transition: 1000ms;
}

.navbar-container h1 {
	font-weight: 900;
	margin: 0;
	font-size: 32px;
}

.navbar-container h1 svg {
	font-size: 23px;
	margin-bottom: 3px;
	background: white;
	color: #007bff;
	margin-left: 2px;
	padding: 4px;
	margin-right: 10px;
	width: 23px;
}

.navbar-container-expanded {
	position: fixed;
	background: linear-gradient(#0070f0, #0050ef);
	top: 50px;
	z-index: 5;
	width: 100vw;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	overflow: hidden;
}

.navbar-container-expanded > a,
.navbar-container-expanded button {
	border: none;
	text-decoration: none;
	color: white;
	padding-left: 14px;
	font-weight: 700;
	font-size: 16px;
	line-height: 2.5;
}

.navbar-container > div {
	font-size: 36px;
}

@keyframes slide-in {
	0% {
		transform: translate(110vw, 0);
		opacity: 0;
	}
	100% {
		transform: translate(0, 0);
		opacity: 1;
	}
}
.navbar-container .desktop-links {
	font-size: 16px;
}

.desktop-links a {
	font-size: 16px;
	color: white;
	margin: 0 15px;
	font-weight: bold;
}

.desktop-links a:hover {
	text-decoration: underline;
}
