.select-one {
	padding: 4px 10px 10px;
	border: 3px solid rgba(0, 0, 0, 0.1);
	border-radius: 8px;
	background: white;
}

.select-one label {
	font-weight: bold;
	font-size: 12px;
	margin-bottom: 8px;
	display: block;
}

.select-one-options > div {
	font-weight: bold;
	display: inline-block;
	padding: 0 20px;
	line-height: 40px;
	border: 3px solid rgba(0, 0, 0, 0.1);
	border-radius: 24px;
	margin-right: 8px;
	color: rgba(0, 0, 0, 0.33);
}

.select-one-options > div.selected {
	background: rgba(0, 0, 0, 0.1);
	color: black;
	border-color: transparent;
}
