.ClientMealPlans-container {
	height: calc(100vh - 51px);
	overflow-y: auto;
}
.ClientMealPlans-container > section {
	display: flex;
	align-items: center;
}
.ClientMealPlans-container .meal-count-input > label {
	display: block;
	font-size: 12px;
	font-weight: bold;
	position: absolute;
	top: -12px;
}
.ClientMealPlans-container select {
	border: 0;
	border-bottom: 1px solid grey;
	border-radius: 0;
	padding: 10px 0;
	width: 100%;
	font-size: 18px;
}
.ClientMealPlans-container > section > button {
	width: 70%;
}

.ClientMealPlans-container footer.footer-action-btns > button.primary + button {
	margin-left: 10px;
}
