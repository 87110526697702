.FindPeople-container {
	height: calc(100vh - 50px);
}

.FindPeople-container > div,
.FindPeople-container > div > section {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.FindPeople-container > div {
	height: calc(100% - 72px);
}
.FindPeople-container > div > .ppl-search-filters {
	height: 25px;
	width: 90%;
	margin-bottom: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.FindPeople-container > div > .ppl-search-filters > .ppl-filter-btn {
	height: 30px;
	line-height: 0;
}
.FindPeople-container > div > .ppl-search-filters > .ppl-filter-btn:focus {
	outline: none;
}
.FindPeople-container > div > section {
	height: calc(100% - 95px);
	overflow-y: auto;
}
.FindPeople-container .Search-container {
	width: 95%;
}
