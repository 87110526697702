.ImageInput-container {
	padding: 10px;
	background: white;
	border: 3px solid rgba(0, 0, 0, 0.1);
	border-radius: 8px;
}
.ImageInput-container label {
	font-weight: bold;
	font-size: 12px;
	margin-bottom: 8px;
	display: block;
}
.ImageInput-container input {
	display: none;
}
.ImageInput-container img {
	width: 100%;
}
.ImageInput-container .image-preview {
	display: block;
	width: 200px;
	height: 200px;
	border-radius: 200px;
	background-position: center center;
	background-size: cover;
	margin: 0 auto 10px;
}
.ImageInput-container .image-preview.rotate-1 {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
}
.ImageInput-container .image-preview.rotate-2 {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}
.ImageInput-container .image-preview.rotate-3 {
	-webkit-transform: rotate(270deg);
	transform: rotate(270deg);
}
