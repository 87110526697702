dl {
  margin: 0;
}

.DataCard {
  text-align: center;
}

.DataCard dt {
  font-size: 24px;
}
