.food-card {
	padding-top: 20px;
	background: white;
	border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.food-card header {
	padding: 0 20px;
}
.food-card header strong {
	max-width: 60vw;
	overflow: hidden;
	display: inline-block;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.food-card main {
	padding: 0 20px;
}
.food-card .subtitle {
	font-size: 12px;
	line-height: 2;
}
.food-card .stat-pill {
	font-weight: normal;
	width: 100%;
	margin: 0;
	padding: 10px;
	background: none;
}
