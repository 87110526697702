.ClientProfile-container,
.ClientProfile-container > div {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.ClientProfile-container > main > section {
	border: 1px solid #ccc;
}
.ClientProfile-container input:focus {
	outline: none;
}
.ClientProfile-container hr {
	width: 100%;
	margin-bottom: 20px;
}
.ClientProfile-container main {
	width: 100%;
	height: 75%;
	padding-bottom: 120px;
	overflow-y: auto;
}
.ClientProfile-container .field {
	align-items: flex-start;
	border-bottom: 1px solid rgb(200, 200, 200);
	transition: 500ms;
	overflow: hidden;
}
.ClientProfile-container .field:last-child {
	border-bottom: 0;
}
/* .ClientProfile-container .field:focus-within {
	border-bottom: 3px solid rgb(0, 187, 255);
} */
.ClientProfile-container .field > label,
.ClientProfile-container .ne-Select-container > label {
	min-width: 120px;
	background: #f5f5f5;
	font-weight: bold;
	font-size: 12px;
	padding: 12px 8px;
	vertical-align: top;
	margin: 0;
	display: inline-block;
	color: rgba(0, 0, 0, 0.63);
	border-right: 1px solid lightgrey;
}
.ClientProfile-container .ne-Select-container {
	margin: 0;
	overflow: hidden;
}
.ClientProfile-container .ne-Select-container select {
	border-radius: 0;
	padding: 10px;
}
.ClientProfile-container .field > input {
	padding: 10px;
	line-height: 1.5;
	width: calc(100% - 120px);
	height: 42px;
}
.ClientProfile-container > div > select {
	width: 100%;
	overflow-x: auto;
	padding-left: 5px;
}
.ClientProfile-container > div > select:disabled {
	color: black;
}
.prof-age-span {
	font-size: 13px;
}
.ClientProfile-container select {
	border: 0;
}
.ClientProfile-container .field > input {
	border: 0;
	border-radius: 0;
	transition: 300ms;
}
.ClientProfile-container .field > input:focus:not([readonly]) {
	background: #eef5ff;
	font-weight: bold;
}
