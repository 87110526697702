.client-photo {
	background-position: center center;
	background-size: cover;
}
.client-photo.rotate-1 {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
}
.client-photo.rotate-2 {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}
.client-photo.rotate-3 {
	-webkit-transform: rotate(270deg);
	transform: rotate(270deg);
}
