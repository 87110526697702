.food-table-container {
	max-height: calc(100% - 60px);
	overflow-y: auto;
	background: white;
}
.FoodLibrary-container > main {
	padding: 0 0 60px;
}
.add-food-drawer .Input-container {
	width: 85%;
	margin-bottom: 5px;
	margin-top: 10px;
}
