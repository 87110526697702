.bg-dark-blue {
	background-color: #173f5f;
}

.bg-blue {
	background-color: #20639b;
}

.bg-green {
	background-color: #3caea3;
}

.bg-red {
	background-color: #ed553b;
}

.bg-yellow {
	background-color: #f6d55c;
}

.bg-grey {
	background: #444;
}

.bg-offwhite {
	background: #f5f5f5;
}

.border-darken-10 {
	border: 1px solid rgba(0, 0, 0, 0.1);
}

.text-blue {
	color: #007bff;
}

.text-darken-25 {
	color: rgba(0, 0, 0, 0.25);
}

.text-darken-33 {
	color: rgba(0, 0, 0, 0.33);
}

.text-darken-50 {
	color: rgba(0, 0, 0, 0.5);
}

.text-darken-75 {
	color: rgba(0, 0, 0, 0.75);
}

.text-red {
	color: #ed553b !important;
}

.border-top-light-grey {
	border-top: 1px solid #eee;
}

.footer-action-btns {
	position: fixed;
	display: flex;
	width: 100%;
	bottom: 0;
	left: 0;
	padding: 10px;
	box-sizing: border-box;
	background: white;
	border-top: 1px solid #eee;
	z-index: 1;
}

.footer-action-btns button.secondary {
	margin-right: 10px;
}

.footer-action-btns button.secondary + button.primary {
	width: calc(100% - 52px);
}

.switch-route {
	margin-top: 50px;
}

.switch-route > header {
	box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
	position: relative;
	background: white;
	z-index: 1;
}

.switch-route > header > h4 {
	margin: 0;
}

.switch-route > main {
	padding: 20px 20px 60px;
	height: calc(100vh - 112px);
	overflow-y: auto;
	background: #f5f5f5;
}

input,
textarea,
select {
	border: none;
	border-left: 1px solid lightgrey;
	padding: 0.5em;
	font-size: 15px;
	line-height: 1.2em;
	background: #fff;
	box-shadow: 1px 1px 1px #fff;
	border-radius: 0.25em;
	-webkit-appearance: none;
	-webkit-box-shadow: 1px 1px 1px #fff;
	-webkit-border-radius: 0.25em;
}

select:focus {
	outline: none;
	border: 3px solid #007bff;
}

/* width */
/* *::-webkit-scrollbar {
	width: 20px;
} */

/* Track */
/* *::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px grey;
	border-radius: 10px;
} */

/* Handle */
/* *::-webkit-scrollbar-thumb {
	background: red;
	border-radius: 10px;
} */

a {
	text-decoration: none;
	color: black;
}

.even-column {
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
}

/* Font weights */
.fw-300 {
	font-weight: 300;
}

.fw-500 {
	font-weight: 500;
}
.fw-600 {
	font-weight: 600;
}
.fw-700 {
	font-weight: 700;
}
/* Toastify */
.Toastify__toast-container {
	bottom: 62px;
	top: auto;
}

@keyframes zoomIn {
	from {
		opacity: 0;
		transform: scale3d(0.3, 0.3, 0.3);
	}

	50% {
		opacity: 1;
	}
}

.zoomIn {
	animation-name: zoomIn;
}

@keyframes zoomOut {
	from {
		opacity: 1;
	}

	50% {
		opacity: 0;
		transform: scale3d(0.3, 0.3, 0.3);
	}

	to {
		opacity: 0;
	}
}

.zoomOut {
	animation-name: zoomOut;
}

.stat-pill {
	font-size: 12px;
	padding: 2px 6px;
	font-weight: bold;
	color: rgba(0, 0, 0, 0.5);
	background: #f5f5f5;
	margin-bottom: 4px;
	margin-right: 8px;
	display: inline-block;
}
.loading-user {
	height: 100vh;
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	font-weight: 900;
	font-size: 36px;
}

.pointer {
	cursor: pointer;
}
