.ClientMealPlanCard {
	padding: 10px 16px;
	background: white;
	border-radius: 8px;
	margin-top: 20px;
}

.ClientMealPlanCard > header > strong {
	font-weight: 900;
	color: #007bff;
}
.ClientMealPlanCard > header > span {
	float: right;
	font-size: 12px;
	line-height: 2;
}

.ClientMealPlanCard ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
.ClientMealPlanCard > main > ul > li {
	border-top: 1px solid rgba(0, 0, 0, 0.1);
	padding-top: 10px;
	margin-top: 10px;
}
.ClientMealPlanCard > main > ul > li > header > strong {
	font-weight: 700;
	color: rgba(0, 0, 0, 0.87);
}

.ClientMealPlanCard > main > ul > li > header > span {
	float: right;
	font-size: 12px;
	line-height: 2;
	color: rgba(0, 0, 0, 0.33);
}

.ClientMealPlanCard > main > ul > li > main {
	color: rgba(0, 0, 0, 0.5);
	font-size: 14px;
}
