#not-mobile {
	display: none;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
		"Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
		"Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

@media (min-width: 1025px) and (max-width: 2000px) {
	body {
		background-color: lavender;
	}
	#root * {
		display: none;
	}
	#not-mobile {
		height: 100vh;
		display: initial;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}
