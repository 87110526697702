.Modal-container {
	position: absolute;
	top: 0;
	left: 0;
	height: calc(100vh - 50px);
	width: 100vw;
	margin-top: 50px;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 8;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
