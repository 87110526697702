.EditMealFoodModal-container {
	position: relative;
	height: 100%;
	width: 100%;
	background-color: white;
	overflow: hidden;
}
.EditMealFoodModal-container > header {
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
	position: relative;
}

.EditMealFoodModal-container > main {
	height: calc(100% - 62px);
	background: #f5f5f5;
}
.EditItem-container input {
	font-size: 32px;
}
