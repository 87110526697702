.container {
	margin-top: 16px;
}
.barContainer {
	height: 25px;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: white;
	border-radius: 5px;
	position: relative;
}
.progressBar,
.fullProgress {
	background-color: transparent;
	border: 2px solid #007bff;
	border-radius: 10px;
	transition: 500ms;
}
.progressBar {
	border-right: none;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	transition: 1000ms;
}

.plannedMetric,
.targetMetric {
	position: absolute;
	top: 0;
	z-index: 1;
}
.plannedMetric {
	left: 5px;
}
.targetMetric {
	right: 5px;
}
