.ClientMealPlan-container {
	height: calc(100vh - 51px);
	overflow-y: auto;
}
.ClientMealPlan-container > section {
	display: flex;
	align-items: center;
}
.ClientMealPlan-container .meal-count-input > label {
	display: block;
	font-size: 12px;
	font-weight: bold;
	position: absolute;
	top: -12px;
}
.ClientMealPlan-container select {
	border: 0;
	border-bottom: 1px solid grey;
	border-radius: 0;
	padding: 10px 0;
	width: 100%;
	font-size: 18px;
}
.ClientMealPlan-container > section > button {
	width: 70%;
}

.ClientMealPlan-container footer.footer-action-btns > button.primary {
	width: 42%;
}

.ClientMealPlan-container footer.footer-action-btns > button.primary + button {
	margin-left: 10px;
}

.ClientMealPlan-container .planned-target-cals {
	background: white;
	border-radius: 0 8px 8px 0;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
	padding: 10px 14px;
	border-left: 5px solid #33cc66;
	font-size: 14px;
}

.ClientMealPlan-container .planned-target-cals.below-70 {
	border-left: 5px solid #ed553b;
}

.ClientMealPlan-container .planned-target-cals.below-90 {
	border-left: 5px solid #f6d55c;
}

.ClientMealPlan-container .planned-target-cals header {
	font-size: 12px;
	color: rgba(0, 0, 0, 0.87);
}

.ClientMealPlan-container .planned-target-cals label {
	margin: 0;
	font-weight: 700;
}

.ClientMealPlan-container .data-card {
	background: white;
	border-radius: 8px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
	padding: 10px 14px;
}

.ClientMealPlan-container .data-card header {
	font-size: 12px;
	font-weight: 700;
	color: rgba(0, 0, 0, 0.87);
}

.ClientMealPlan-container .data-card main {
	font-size: 18px;
	font-weight: 700;
}

.ClientMealPlan-container .planned-target-cals .planned-target-cals-value {
	font-size: 18px;
	font-weight: 700;
}
.ClientMealPlan-container
	.planned-target-cals
	.planned-target-cals-value
	+ span {
	color: rgba(0, 0, 0, 0.33);
}

.meal-card-table {
	width: 100%;
}

.meal-card-table th,
.meal-card-table td {
	text-align: left;
	border-bottom: 1px solid lightgrey;
	font-size: 12px;
}
