.ClientList-container {
	position: relative;
}
.ClientList-container h4 + div {
	border-top: 2px solid #eee;
}
.ClientList-container h4 strong {
	vertical-align: middle;
}
.ClientList-container h4 span {
	background: #eee;
	padding: 2px 8px;
	margin-left: 8px;
	border-radius: 4px;
	font-size: 18px;
	vertical-align: middle;
}
.ClientList-container > hr {
	width: 100%;
	margin-bottom: 0;
}
.ClientList-container > div {
	padding: 10px 0;
	height: calc(100vh - 177px);
	overflow-y: auto;
}
.ClientList-container > .footer-action-btns {
	overflow: hidden;
}
.ClientList-container.switch-route > main {
	height: calc(100vh - 174px);
}
