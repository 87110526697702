.MealModal-container {
	position: relative;
	height: 100%;
	width: 100%;
	background-color: white;
	overflow: hidden;
}
.MealModal-container > header {
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
	position: relative;
}

.MealModal-container .Search-container {
	width: 100%;
}

#inputSearch {
	margin-right: 10px;
}

.MealModal-container > main {
	height: calc(100% - 62px);
	background: #f5f5f5;
}

.MealModal-container .table {
	margin: 0;
	background: white;
}

.MealModal-container .table th {
	border-top: 0;
}

.MealModal-container .Search-container input {
	transition: 500ms;
}
.MealModal-container input:focus {
	outline-color: aqua;
	outline-width: 4px;
}
.MealModal-container .Input-container + .Input-container {
	margin: 0;
}
.MealModal-container .lookup-food-entry {
	height: calc(100% - 60px);
	transition: 500ms;
}
.MealModal-container .lookup-food-entry .food-table-container {
	max-height: calc(100% - 60px);
	overflow-y: auto;
	border-top: 1px solid #ddd;
}

.MealModal-container .me-btn-container {
	width: 100%;
	display: flex;
	justify-content: space-between;
	background: white;
	padding: 10px;
	border-top: 1px solid #eee;
	position: fixed;
	z-index: 1;
}

.MealModal-container .me-btn-container button {
	width: calc(100% - 52px);
}

.MealModal-container .mm-btn-container {
	width: 100%;
	display: flex;
	justify-content: space-between;
	background: white;
	padding: 10px;
	border-top: 1px solid #eee;
}
.MealModal-container .mm-btn-container > button + button {
	width: calc(100% - 52px);
}

.EditItem-container input {
	font-size: 32px;
}
