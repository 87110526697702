.CreateClient-container {
	align-items: center;
}
.CreateClient-container > main > form {
	width: 100%;
	height: 100%;
	overflow-y: auto;
}

.CreateClient-container .Input-container + .Input-container {
	margin-top: 20px;
	margin-bottom: 20px;
}

.CreateClient-container .ImageInput-container {
	margin-bottom: 20px;
}

.birthdate-container {
	margin-bottom: 10px;
}

.birthdate-container > select {
	height: 25px;
	padding-left: 2px;
	font-size: 12px;
	background-color: white;
	border: none;
	border-bottom: 1px solid grey;
	border-radius: 0;
}

.birthdate-container > select:focus {
	outline: none;
	padding-bottom: 0;
	border-bottom: 3px solid rgb(0, 187, 255);
}

.CreateClient-container > form > div > .birthdate-container {
	display: flex;
	justify-content: space-between;
}

.CreateClient-container > form > section {
	height: 10%;
	width: 100%;
}
.CreateClient-container > form button:first-child {
	width: calc(40% - 20px);
	margin-right: 20px;
}
.CreateClient-container > form button + button {
	width: 60%;
}
.CreateClient-container .select-one {
	margin: 20px 0;
}
.CreateClient-container .birthdate-input {
	margin: 20px 0;
}
